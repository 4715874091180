import http from '@/utils/http';


export const getPlayers = (parameter) => {
  return http.request({
    url: '/v1/relation/games/player/list',
    method: 'get',
    params: parameter
  })
}
